export default {
  PleaseInput: '请输入',
  PleaseSelect: '请选择',
  login: {
    msg: '请输入用户名和密码',
    p_phone_email:'请输入手机号/邮箱',
    p_password:'请输入密码',
    Forgot_password:'忘记密码？',
    login_tip:'登录即表示您已同意平台',
    Service_Agreement:'服务协议',
    Login:'登录',
    phm_title:'设备健康管理系统',
    default_title:'工业设备实时预警系统',
    placeholder: '语言切换',
    Supplementary_personal_information:'补充个人信息',
    Password_reset :'密码重置',
    Register :'注册',
    Obtain_verification_code:'获取验证码',
    p_verification_code:'请输入验证码',
    p_password_8:'请设置至少8位密码',
    Confirm_password_again:'再次确认密码',
    p_phone_email_or:'请输入您的手机号或邮箱',
    p_privacy_policy:'请同意服务协议和隐私政策',
    Resend_verification_code:'重新获取验证码',
    languages: [
      {
        label: 'English',
        value: 'en'
      },
      {
        label: '中文',
        value: 'zh_CN'
      }
    ],
    seconds:'{s}s后重新获取',
    p_company_name:'请填写您的公司名称',
  },
  register: {
    deviceID: "设备ID",
    confirmPassword: "确认密码",
    meAgreeWith: "我同意",
    termsUse: "使用条款",
    privacyPolicy: "隐私政策",
    and: '和',
    submit: '提交',
    tip: '已经有账户了吗？',
    msg: {
      email: "请输入邮箱",
      mobile: "请输入手机号",
      id: "请输入设备ID",
      minimum: "密码必须至少包含8个字符",
      match: "密码不匹配",
      use: "请同意使用用户协议和隐私协议",
      characters: "设置不少于8个字符的密码",
      register: "注册成功",
    },
  },
  personalInformation: {
    msg: {
      company: '您的公司名称不能为空',
      industry: '您的行业不能为空',
      firstName: '您的名字不能为空',
      lastName: '您的姓氏不能为空',
    }
  },
  home: {
    pressure: '压力',
    temperature: '温度',
    humidity: '湿度',
    flow: '流动',
    frequency: '频率',
    noise: '噪音',
    torque: '力矩',
    displacement: '移位',
    speed: '速度',
    vibration: '振动',
    illuminance: '照度',
    acceleration: '加速度',
    DI: 'DI',
    DO: 'DO',
    vibtate: 'VIBTATE',
    other: 'other',
    na: 'n/a',
    OFF: 'OFF',
    ON: 'ON',
    mg: '毫克',
  },
  confirmButtonText: '确认',
  cancelButtonText: '取消',
  router: {
    login: 'RIIAlert 登录',
  },
  configureDashboard: {
    deleteDashboard: '您确定要删除这个仪表盘吗？',
    msg: {
      DashboardName: '仪表盘名称不能为空',
    }
  },
  configureAlarm: {
    confirms: {
      text: '您确认要删除此报警？',
    }
  },
  configureUsers: {
    confirms: {
      text: '您确定要删除此用户吗？',
    },
  },
  industry: {
    selectIndustry: '请选择您的行业',
    industries: [
      "农业",
      "服装",
      "汽车",
      "银行",
      "生物技术",
      "化学物质",
      "通信",
      "建设",
      "咨询",
      "教育",
      "电子产品",
      "能源",
      "工程",
      "娱乐场所",
      "环境",
      "金融",
      "餐饮",
      "政府",
      "医疗保健",
      "旅馆",
      "保险",
      "机械",
      "制造业",
      "媒体",
      "非营利性",
      "石油和天然气",
      "娱乐",
      "零售",
      "服务",
      "航运",
      "技术",
      "电信",
      "运输",
      "公用事业公司",
      "其他",
    ],
    msg: {
      industryCannot: '选择行业不能为空',
    }
  },
  name: {
    enterName: '请输入您的姓名'
  },
  app: {
    Log_IN: '登录',
    Inviter: '邀请人',
    owner: '管理员',
    SignOut: '退出',
    CreateLinkedAccount: '创建链接帐户',
    CONFIGURATION: '配置',
    CONTACTS: '联系人',
    LinkedAccounts: '关联账户',
    confirmText: '确定要退出登录吗？'
  },
  ModifySuccess: '修改成功',
  Modify_success_slot: '修改{slot}成功',
  Modify_failed_slot: '修改{slot}失败',
  LoginFailed: '登录失败，请重新登录',
  serverIsBusy: '服务器正忙',
  networkError: '网络错误',
  DateFormat: '年 - 月 - 日',
  pAlarmName: '输入报警名称',
  SetPassword: '设置您的密码',
  ConfirmPassword: '确认您的密码',
  cPassword: '确认密码',
  m_differ: '输入的密码不同',
  Set: '设置',
  Reset: '重置',
  ChooseType: '选择类型',
  ChooseChannel: '选择通道',
  EnterDashboardName: '输入仪表板名称',
  cThresholdType: '选择阈值类型',
  noPermission:'暂无权限使用该功能，请联系商务开通！',
  pOverview:{
    FaultHandlingRate:'故障处理率',
    ProcessedAlarm:'已处理报警',
    AlarmTotal:'报警总数',
    RunTimeRatio:'运行时长占比',
    RunningTimeDays: '运行时长（天）',
    TotalDurationDays:'总时长（天）',
    MaintenanceFrequencyThisMonth:'本月维修次数',
    ComparedToLastMonth:'相较上月以来',
    NumberOfInspectionsThisMonth:'本月检查次数',
    DeviceHealthStatus:'设备健康状态',
    DeviceHealth:'设备健康',
    DeviceHealthLevel:'设备健康等级',
    DeviceMaintenanceReminder:'设备保养提醒',
    RecentMaintenanceTime:'最近保养时间',
    RemainingTimeForNextMaintenance:'下次保养剩余时间',
    DeviceAlarmRanking:"设备报警排名",
    DeviceAlarmTOP:"设备报警TOP",
    timeList: {
      1: '本月',
      2: '上月',
      3: '全年',
    },
    DeviceDeactivationStatistics:'设备停用统计',
    frequency:'次数',
    PlanDowntime:'计划停用时长',
    UnplannedDowntime:'非计划停用时长',
    RunningTimeHours:'运行时长',
    DeactivationTimes:'停用次数',
    hours:'小时'
  },
  pHome:{
    DeviceMonitoring:'设备监测',
    DeviceTotalNumber:'设备总数',
    NormalOperation:'正常运行',
    SensorMonitoring:"传感器监测",
    SensorTotalNumber:"传感器总数",
    NormalCollection:"正常采集",
    PendingAlarmRecords:"待处理报警记录",
    PendingAlarm:"待处理报警",
    Handling:"处理",
    DeviceStatusStatistics:"设备状态统计",
    AlarmProcessingStatistics:'报警处理统计',
    Device_type_statistics:'设备类型统计',
    AlarmStatistics:'报警统计',
    Device_alarm_trend:'设备报警趋势',
    Device_time_utilization_rate:'设备时间稼动率',
    StartDate:'开始日期',
    EndDate:'结束日期',
    to:'至',
    Vibration_monitoring_point:'振动测点',
    expert_report:'专家报告',
    Reports_total:'报告总数',
    report_update_time:'最近报告更新时间',
    offline_notice:'离线通知',
    Vibration_speed_detection:'振动速度检测',
  },
  DigitalLargeScreen:'数字大屏',
  health:'健康',
  Good:'良好',
  warn:'警告',
  dangerous:'危险',
  SubHealth:'亚健康',
  fault:'故障',
  offline:'离线',
  unknown:'未知',
  A_level:'A级（健康）',
  B_level:'B级（良好）',
  C_level:'C级（警告）',
  D_level:'D级（危险）',
  Unpaid:'未支付',
  Processed:'已处理',
  PendingProcessing:'待处理',
  Processing:'处理中',
  Total:'总数',
  HighAlarm:'高报',
  lowAlarm:'低报',
  High_low_alarm:'高低报',
  ISO_alarm:'ISO报警',
  ISO_C_LevelAlarm:'ISO C级报警',
  ISO_D_LevelAlarm:'ISO D级报警',
  C_LevelAlarm:'C级报警',
  D_LevelAlarm:'D级报警',
  TemperatureAlarm:'温度报警',
  Alarm_time:'报警时间',
  Operation:'操作',
  Confirm_success:'确认完成',
  Please_select_data:'请先选择数据',
  Search:'查询',
  pAlarm:{
    Time:'时间',
    Alarm_name:'报警名称',
    Device_name:'设备名称',
    Type:'类型',
    Status:'状态',
    Batch_confirm:'批量确认',
    Current_value:'当前值',
    Threshold:'阈值',
    Update_time:'更新时间',
    Creation_time:'创建时间',
    Processed_by:'处理人',
    Confirmed:'已确认',
    Confirm:'确认',
    DI_alarm:'DI报警',
    DO_alarm:'DO报警',
    Alarming:'报警中',
    Alarm_confirmed:'报警已确认',
    Restored_normal_unconfirmed:'恢复正常未确认',
    Restored_normal_confirmed:'恢复正常已确认',
  },
  cancel_k:'取 消',
  confirm_k:'确 定',
  alertConfirmDialog:{
    Confirmed_alarm:'确认报警',
    Alarm_cause_confirmed:'报警原因确认',
    Processing_note:'处理备注',
    On_site_images:'现场图片',
    Upload_img3_tip:'只能上传图片，最多三张，且单张大小不超过5MB',
    alarm_cause:'报警原因',
  },
  Please_select_slot:'请选择{slot}',
  Upload_three_images:'最多上传三张图片',
  alertDetails:{
    Sensor_name:'传感器名称',
    value:'值',
    Alarm:'报警',
    Recovery:'恢复',
    time:'时间',
    Alarm_threshold:'报警阈值',
    Alarm_type:'报警类型',
    Collection_time:'采集时间',
    Fault_cause_recommendation:'故障原因推荐',
    Fault_cause_confirmation:'故障原因确认',
    view:'查看',
    reason:'原因',
    role_alarm:'角色报警',
    system_alarm:'系统报警',
    notice:'通知',
  },
  count:'计数',
  normal:'正常',
  Operation_success:'操作完成',
  start:'开始',
  end:'结束',
  No_Data:'暂无数据',
  historyData:{
    History_data:'历史数据',
    sensor:'传感器',
    Time_domain_data:'时域',
    Download_data:'下载数据',
    Refresh:'刷新',
    Maximum_value:'最大值',
    Frequency_domain_data:'频域数据',
    Click_device_model:'点击可进行设备模型优化',
    Important_note:'重要提示',
    model_confirm_tip:'此操作会用选择的数据段进行优化设备模型，请谨慎操作！',
    Modeling_tip:'正在建模中，请等待...',
    hours_less_24_tip:'区间不能小于24小时',
    Frequency_spectrum:'频谱',
    Trend_waveform:'趋势波形',
    Envelope_demodulation:'包络阶次',
    Multiple_time_domain_waveform:'多时域波形',
    Cepstrum_analysis:'倒谱分析',
    Order_analysis:'阶次分析',
    Envelope_trend:'包络趋势',
    Waterfall_plot:'瀑布图',
    Waveform_data:'原始波形',
    Filter_data:'功率谱密度',
    Waveform_filter_data:'滤波波形',
  },
  switchTimeList:{
    3: '近1天',
    5: '近3天',
    6: '近7天',
    7: '自定义时间',
  },
  device: {
    DeviceName: '设备名称',
    DeviceType: '设备类型',
    DeviceArea: '设备区域',
    DeviceLevel: '设备等级',
    ISO_vibration_standard: 'ISO振动标准',
    ISO_vibration_type: 'ISO振动类型',
    reboot: '确定要重启设备吗？',
    Health_status:'健康状态',
    Running_status:'运行状态',
    Number_of_sensors:'传感器数量',
    Operations:'运维',
    Running:'运行',
    Shutdown:'停机',
    IOT_abnormality:'IOT异常',
    Device_working_status:'设备工作状态',
    Total_device_alarm_number:'设备总报警数',
    pieces:'条',
    device_working_time:'设备工作时长',
    device_down_time:'设备停机时长',
    Device_info:'设备信息',
    sensor_info:'传感器信息',
    details:'详情',
    Please_select_time:'请先选择时间',
    Sensor:'传感器',
    click_view_data:'点击查看该时间点频谱',
    Device_fault_recommendation:'设备故障推荐',
    Device_fault_recommendation_tip:'设备故障推荐参考，若设备状态持续出现异常，则需要关注故障分类中较高的分值',
    Device_status_monitoring:'设备状态监测',
    Device_status_monitoring_tip:'设备状态参考曲线，若数据持续超过阈值则需要关注',
    Update_threshold_tip:'提醒：该阈值已使用超过30天，请及时更新',
    State_threshold:'状态阈值',
    Modeling:'建模',
    Add_device:'新增设备',
    Please_select_device:'请先选择设备',
    Modeling_tip:'设备建模将更新设备算法模型，建模期间请保持设备正常平稳状态运行（开机3小时后），建模时间持续24小时，建议每隔30天进行一次建模',
    Batch_modeling:'批量建模',
    del_device_tip:'删除设备前，请先与IOT模块解除绑定！',
    Delete_device:'删除设备',
    device:'设备',
    Name:'名称',
    City:'所在城市',
    Detailed_address:'详细地址',
    Vibration_monitoring_switch:'振动监测开关',
    Standard_example:'标准示例',
    Reference_documents:'参考文档',
    Remark:'备注',
    Brand:'品牌',
    Level:'等级',
    Device_modeling:'设备建模',
    Important_reminder:'重要提醒',
    Start:'开始',
    Sensor_list:'传感器列表',
    Unbind:'解除绑定',
    Estimated_time_hours:'预估时长（小时）',
    Schedule:'进度',
    Recent_start_time:'最近开始时间',
    Recent_end_time:'最近结束时间',
    trainingStatusList:{
      1: '数据采集中',
      2: '算法模型初始化',
      3: '建模成功',
      4: '建模失败',
      5: '失效',
    },
    Start_modeling:'开始建模',
    Continue_modeling:'是否继续建模',
    Please_select_sensor:'请先选择要解绑的传感器',
    Unbind_sensor_tip:'解除IOT配置后，IOT数据将失效清空，谨慎操作！',
    Unbind_successful:'解绑成功',
    Real_time_data_display:'实时数据展示',
    Latest_update_time:'最近更新时间',
  },
  operations:{
    Operations_info:'运维信息',
    Inspection_records:'检查记录',
    Detection_records:'检测记录',
    Maintenance_records:'保养记录',
    Repair_records:'维修记录',
    Start_stop_records:'启停记录',
    operationStatus:{
      0: '异常',
      1: '正常'
    },
    Import_failed:'导入失败',
    Import_successful:'导入成功',
    Inspection_content:'检查内容',
    Select_date:'选择日期',
    Inspection_status:'检查状态',
    Inspection_date:'检查日期',
    Inspector:'检查人',
    Inspection_indicators:'检查指标',
    Inspection_results:'检查结果',
    Maintenance_status:'保养状态',
    Maintenance_manager:'保养负责人',
    Maintenance_amount:'保养金额',
    Maintenance_responsible_unit:'保养负责单位',
    Maintenance_start_date:'保养开始日期',
    Maintenance_end_date:'保养结束日期',
    Maintenance_date:'保养日期',
    Next_maintenance_date:'下次保养日期',
    Registrant:'登记人',
    Attachments:'附件',
    Repair_status:'维修情况',
    Repair_responsible_person:'维修负责人',
    Repair_responsible_unit:'维修负责单位',
    Repair_start_date:'维修开始日期',
    Repair_end_date:'维修结束日期',
    Repair_amount:'维修金额',
    Repair_date:'维修日期',
    Detection_content:'检测内容',
    Detection_status:'检测状态',
    Detection_date:'检测日期',
    Internal_inspector:'内部检测人',
    External_inspector:'外部检测人',
    External_unit:'外部单位',
    Detection_result:'检测结果',
    Start_stop_status:'启停状态',
    Switch:'开关',
    Reason_for_restoration:'恢复理由',
    Reason_for_discontinuation:'停用理由',
    Suspension_measures:'停用措施',
    Boot:'开机',
    startStopTypeSelected:{
      1: '计划停机',
      2: '非计划停机',
      3: '计划开机',
      4: '非计划开机'
    }
  },
  upload_5Mb_tip:'上传文件不超过5MB',
  el_upload_text1:'将文件拖到此处，或',
  el_upload_text2:'点击上传',
  Add_k:'新增',
  Edit_k:'编辑',
  Add:'新增',
  Edit:'编辑',
  Delete:'删除',
  Template_download:'模板下载',
  Import:'导入',
  Import_successful:'导入成功',
  template_slot:'{slot}模板',
  del_record_tip:"您确定要删除此记录吗？",
  Delete_successful:'删除成功',
  Save:'保存',
  Add_successfully:'添加成功',
  statisticalReport:{
    SN:'序号',
    Download_report:'下载报告',
    del_report_tip:'您确认要删除该报告？'
  },
  area:{
    Area_name:'区域名称',
    Add_area:'新增区域',
    Delete_area:'删除区域',
    Delete_area_tip:'谨慎操作！请确保区域下无设备！',
    Number_of_devices:'设备数量',
  },
  IOT:{
    Inactive:'未激活',
    IOT_name:'IOT名称',
    IOT_SN:'IOT编号',
    IOT_model:'IOT型号',
    IOT_type:'IOT类型',
    Location:'位置',
    Communication_validity_period:'通信有效期',
    Indefinite:'无限期',
    renew:'续费',
    Activate_network_communication:"开通网络通讯",
    Install_date:"安装日期",
    Disabled:'禁用',
    Enable:'启用',
    Alarm_config:'报警设置',
    IOT_disabled_tip:'该IOT已禁用',
    IOT_enabled_tip:'该IOT已启用',
    Channel_name:'通道名称',
    Unit:'单位',
    High_alarm_deviation:'高报浮动值',
    Low_alarm_deviation:'低报浮动值',
    Alarm_trigger_condition:'报警触发条件',
    alarm_config_tip:'连续多少个值超过报警阈值，则触发报警',
    Close:'关闭',
    Open:'开启',
    Basic_info:'基础信息',
    Add_sensor:'添加传感器',
    Activation_date:'激活日期',
    IOT_operation:'IOT操作',
    Restart_IOT:'重启IOT',
    general_configuration:'通用配置',
    Basic_config:'基础配置',
    Sensor_config:'传感器配置',
    Sensor_type:'传感器类型',
    Model:'型号',
    Install_device:'安装设备',
    upload_img_5mb_tip:'请上传图片格式文件，大小在5MB以内',
    enter_value_min_tip:'请输入值不小于',
    del_channel_tip:'确定要删除此通道？',
    Channel_type:'通道类型',
    Source_select_channel:'来源(请选择频道)',
    Restarting:'开始重启',
    Channel_modify_success:'通道修改成功',
    Basic_info_modify_success:'基础信息修改成功',
    complete_configuration_items:'请完善配置项',
    config:'配置',
    host:'主机',
    slave:'从机',
    Baud_rate:'波特率',
    Address:'地址',
    server:'服务器',
    Port:'端口',
    client:'客户端',
    Remote_device_IP:'远程设备IP',
    Sensor_model:'传感器型号',
    Sensor_address:'传感器地址',
    enter_sensor_address:'请输入传感器地址',
    enter_sensor_address_slot:'传感器地址必须在{slot}之间',
    Sensor_position:'传感器位置',
    rotary_speed:'转速',
    Nameplate:'铭牌',
    image:'图片',
    Expand_information:'拓展信息',
  },
  cannot_be_empty_slot:'{slot}不能为空',
  Save_successful:'保存成功',
  user:{
    Add_role_user:'新增角色用户',
    Account:'账户',
    Role:'角色',
    Name:'姓名',
    Device_group:'设备组',
    Pending_confirmation:'待确认',
    Edit_user:'编辑用户',
    Add_user:'新增用户',
    Invitation_method:'邀请方式',
    Role_type:'角色类型',
    Assign_alarms:'分配告警',
    Assign_dashboard:'分配仪表盘',
    Mobile:'手机',
    Email:'邮箱',
    typeObj:{
      '手机': 1,
      '邮箱': 2,
      '微信': 3,
    }
  },
  role:{
    Add_role:'新增角色',
    Edit_role:'编辑角色',
    Role_name:'角色名称',
    Role_type:'角色类型',
    built_in:'内置',
    custom:'自定义',
    Menu_permissions:'菜单权限',
    Edit_menu_permissions:'编辑菜单权限',
    del_role_tip:'您确定要删除此角色吗？',
    Role_identifier:'角色标识',
    Display_order:'显示顺序',
    Expand_Fold:'展开/折叠',
    Select_All_Select_None:'全选/全不选',
  },
  sms:{
    SMS_balance:'短信余量',
    Recharge:'充值',
    Select_year:'选择年份',
    Month:'月份',
    Consumption_quantity:'消费数量',
    Recharge_record:'充值记录',
    Monthly_billing:'月账单',
    Recharge_account:'充值账号',
    Recharge_SMS_volume:'充值短信量',
    Recharge_amount:'充值金额',
    Scan_pay:'扫码支付',
    Pay:'付费',
    Select_package:'选择套餐',
    Payment_method:'支付方式',
    WeChat:'微信',
    Cost:'费用',
    Go_pay:'去支付',
    Select_package_tip:'请先选择套餐'
  },
  alarmPush:{
    Alarm_push_config:'报警连续推送配置',
    push_tip:'推送间隔不得低于10分钟',
    Email_push_interval:'邮件推送间隔（分钟）',
    SMS_push_interval:'短信推送间隔（分钟）',
    Continuous_push_switch:'连续推送开关',
    Save_k:'保 存',
    Set_successfully:'设置完成',
  },
  personal:{
    Personal_center:'个人中心',
    Role_identity:'角色身份',
    Role_nickname:'角色昵称',
    Edit_role_nickname:'修改角色昵称',
    Company_name:'公司名称',
    Industry:'行业',
    p_name:'请填写您的姓名',
    p_company_name:'请填写您的公司名称',
    p_industry:'请选择您的行业',
    Account_manage:'账户管理',
    Change_password:'修改密码',
    Device_group_settings:'设备组设置',
    Modify:'修改',
    Binding:'绑定',
    Retrieve_again:'重新获取',
    Retrieve_again_slot:'{slot}秒后重试',
    p_modify_code:'手机验证码',
    p_email_code:'邮箱验证码',
    p_new_mobile:'请输入新的手机号',
    p_new_email:'请输入新的邮箱地址',
    get_code_tip:'请先点击绑定或修改，获取验证码',
    Binding_successful:'绑定成功',
    Old_password:'原密码',
    New_password:'新密码',
    New_password_confirm:'新密码确认',
    Old_password_tip:'请输入原密码',
    New_password_tip:'请输入新密码',
    New_password_confirm_tip:'请输入确认密码',
    password_tip:'两次密码不一致',
    Device_group_alias:'设备组别名',
    Device_group_name:'设备组名称',
    Device_group_sn:'设备组编号',
    Inviter:'邀请者',
    Creator:'创建者',
    Account_role:'账户角色',
    Select_device:'选择设备',
    Please_enter_device_name:'请输入设备名称',
    Optional_device:'可选设备',
    Current_device:'当前设备',
    Please_select_device:'请选择设备',
    del_device_group:'确定要删除此设备组？',
  },
  dashboard:{
    Show_all_channels:'显示全部通道',
    Display_only_alarm_channels:'仅显示报警通道',
    Channel_configuration_parameters:'通道配置参数',
    Channel_data:'通道数据',
    All:'全部',
    confirmed:'已确认',
    Dashboard_name:'仪表盘名称',
    Add_dashboard:'新增仪表盘',
    Creator_name:'创建者名称',
    Modification_time:'修改时间',
    Default:'默认',
    default_dashboard_tip:'默认仪表板已设置',
    Edit_dashboard:'编辑仪表盘',
    Selected_channel:'已选通道',
    remove_all :'移除全部',
    p_dashboard_name:'请输入仪表盘名称',
  },
  alarm:{
    Alarm_description:'报警描述',
    Alarm_value:'报警值',
    Please_remarks:'请填写备注',
    Please_pictures:'请上传现场图片',
    device_code:'设备编码',
    Alarm_frequency:'报警频率',
    Alarm_trend:'报警趋势',

  },
  Download_history:'下载历史',
  Device_model:'设备型号',
  Download_mode:'下载模式',
  Date:'日期',
  Select_data:'选择数据',
  Data_packet:'数据包',
  p_download_mode:'请选择下载模式',
  p_select_date:'请选择日期',
  Download_time:'下载时间',
  Device_SN:'设备SN',
  Download_again:'再次下载',
  Downloading_please_wait:'正在下载中，请等待...',
  Add_Modbus:'添加Modbus',
  Alarm_configuration:'报警配置',
  high_and_low_err:'高报和低保数据错误',
  p_time:'请选择时间',
  device_disabled:'该设备已禁用',
  device_enabled:'该设备已启用',
  Add_Modbus_virtual_device:'添加Modbus虚拟设备',
  Parent_device:'主设备',
  Continuous_address_read_multiple:'连续地址多读',
  p_name_10:'请输入10位以内的名称',
  Device_address:'设备地址',
  p_0_255:'请输入0~255的地址',
  Yes:'是',
  No:'否',
  Universal_Modbus:'通用型modbus',
  p_parent_device:'请选择主设备',
  p_select_model:'请选择型号',
  p_device_address:'请输入设备地址',
  Adding_please_wait:'正在添加，请等待...',
  Add_Modbus_channel:'添加Modbus通道',
  p_channel_name:'请输入通道名称',
  Register_address:'寄存器地址',
  channel:'通道',
  Add_alarm_rules:'新增报警规则',
  Creation_date:'创建日期',
  Creator:'创建人',
  Tip:'提示',
  Role_alarm_name:'角色报警名称',
  P_role_alarm_name:'请输入角色报警名称',
  p_select_channel:'请选择通道',
  p_select_type:'请选择类型',
  p_alarm_value:'请输入高报值',
  p_floating_value:'请输入高报浮动值',
  p_low_value:'请输入低报值',
  p_float_low_value:'请输入低报浮动值',
  low_high_tip:'低报值不能大于高报值',
  chartDateRangeList : [
    {label: '自定义', value: 0},
    {label: '1小时', value: 1},
    {label: '6小时', value: 2},
    {label: '1天', value: 3},
    {label: '7天', value: 4},
  ],
  Abnormal_device:'非正常运行设备',
  Abnormal_sensor:'离线传感器列表',
  Maintenance_content:'维修内容',
  Report_Name:'报表名称',
  logo_phm_title:'设备健康管理系统',
  collector:{
    add:'新增采集器',
    edit:'编辑采集器',
    name:'采集器名称',
    sn:'采集器编码',
    type:'采集器类型',
    model:'采集器型号',
    Alarm_config:'报警配置',
    customize:'自定义',
    ISO_Standard:'ISO标准',
    Alarm_level:'报警等级',
    X_intensity:'x轴烈度',
    Y_intensity:'y轴烈度',
    Z_intensity:'z轴烈度',
  }
  //  $t('PleaseInput'): '请输入',
  //   $t('PleaseSelect'): '请选择',
};

