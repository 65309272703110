import {mapState, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapState({
      themeName: 'themeName',
      isBt:"isBt",
      isLt:"isLt",
      isXbk:"isXbk",
      isDt:"isDt",
      tenantKey:'tenantKey',
    }),
    ...mapGetters({
      theme: "theme"
    })
  },
  data() {
    return {
      LINX_DT: 'LINX-DT'
    }
  }
}
