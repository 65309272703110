<template>
  <div :ref="elRef" :class="[prefixCls]" :style="styles"></div>
</template>

<script>
import { debounce } from 'lodash-es';

export default {
  name: 'EChart',
  props: {
    options: {
      type: Object,
      required: true,
    },
    width: {
      type: [Number, String],
      default: '',
    },
    height: {
      type: [Number, String],
      default: '500px',
    },
    elRef:{
      type:String,
      default: 'elRef',
    }
  },
  data() {
    return {
      echartRef: null,
      prefixCls: 'echart',
      contentEl: null,
      resizeHandler:""
    };
  },
  computed: {
    styles() {
      const width = typeof this.width === 'string' ? this.width : `${this.width}px`;
      const height = typeof this.height === 'string' ? this.height : `${this.height}px`;
      return { width, height };
    },
  },
  watch: {
    options: {
      handler(newOptions) {
        if (this.echartRef) {
          this.clearChart()
          this.echartRef.setOption(newOptions);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart();
    this.createDebouncedResizeHandler();
    window.addEventListener('resize', this.resizeHandler);
    this.contentEl = document.getElementsByClassName('layout-content')[0];
    if (this.contentEl) {
      this.contentEl.addEventListener('transitionend', this.contentResizeHandler);
    }
  },
  beforeDestroy() {
    this.removeLegendSelectChangedListener(); // 清除 legendselectchanged 监听器
    window.removeEventListener('resize', this.resizeHandler);
    if (this.contentEl) {
      this.contentEl.removeEventListener('transitionend', this.contentResizeHandler);
    }
    if (this.echartRef) {
      this.echartRef.dispose();
    }
  },
  methods: {
    clearChart(){
      if(this.echartRef) this.echartRef.clear();
    },
    initChart() {
      if (this.$refs[this.elRef] && this.options) {
        this.echartRef = this.$echarts.init(this.$refs[this.elRef]);
        this.echartRef.setOption(this.options);

        // 添加 legendselectchanged 事件监听器
        this.echartRef.on('legendselectchanged', this.handleLegendChange);
      }
    },
    handleLegendChange(params) {
      this.$emit('legendselectchanged',params)
    },
    removeLegendSelectChangedListener() {
      if (this.echartRef) {
        this.echartRef.off('legendselectchanged');
      }
    },
    createDebouncedResizeHandler() {
      this.resizeHandler = debounce(() => {
        if (this.echartRef) {
          this.echartRef.resize();
        }
      }, 100);
    },
    contentResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.resizeHandler();
      }
    },
  },
  activated() {
    if (this.echartRef) {
      this.echartRef.resize();
    }
  },
};
</script>

<style scoped>
.echart {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
