import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import mixin from './mixin'
import './assets/icons' // icon
import storage from './fetch/storage'
import api from '@/fetch/api'

import tool from '@/fetch/tool'
import dayjs from "dayjs";
import {getMathResult} from "@/fetch/SDMath"
import '@/plugins/ant'
// import * as echarts from 'echarts';
import 'echarts-gl';
import echarts from '@/plugins/echarts';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import EChart from './components/EChart/index.vue';
Vue.component('EChart', EChart);

Vue.use(VueViewer)

Vue.use(VueI18n)
Vue.mixin(mixin)
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.config.productionTip = false

Vue.prototype.$getMathResult = getMathResult
Vue.prototype.$dayjs = dayjs
Vue.prototype.$echarts = echarts
Vue.prototype.$api = api
Vue.prototype.$storage = storage
Vue.prototype.$tool = tool
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'infoMsg') {
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
// 注册全局按钮权限指令
Vue.directive('permission', {
  inserted(el, binding) {
    const requiredPermission = binding.value;
    // 判断指令传递的权限是否在用户的权限列表中
    const hasPermission = store.state.permissions.includes(requiredPermission);
    // 如果没有权限，隐藏按钮
    if (!hasPermission) {
      el.style.display = 'none';
    }
  }
});


const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
