export default {
  PleaseInput: 'Enter',
  PleaseSelect:'Select',
  login: {
    msg: 'Please enter username and password',
    p_phone_email:'Enter mobile/email',
    p_password:'Please enter password',
    Forgot_password:'Forgot password?',
    login_tip:'By logging in, you have agreed to the platform',
    Service_Agreement:'Service Agreement',
    Login:'Login',
    phm_title:'Fault prediction and health management system',
    default_title:'Industrial device real time alert system',
    placeholder: 'Select Language',
    Supplementary_personal_information:'Supplementary personal information',
    Password_reset :'Password reset',
    Register :'Register',
    Obtain_verification_code:'Get verification code',
    p_verification_code:'Please enter the verification code',
    p_password_8:'Password must have at least 8 digits',
    Confirm_password_again:'Confirm password again',
    p_phone_email_or:'Please enter your phone number or email address',
    p_privacy_policy:'Please agree to the service agreement and privacy policy',
    Resend_verification_code:'Resend verification code',
    languages: [
      {
        label: 'English',
        value: 'en'
      },
      {
        label: '中文',
        value: 'zh_CN'
      }
    ],
    seconds:'{s}s retrieve',
    p_company_name:'Please enter your company name',
  },
  register: {
    deviceID: "Device ID",
    confirmPassword: "Confirm password",
    meAgreeWith: "I agree with",
    termsUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    and: 'and',
    submit: 'Submit',
    tip: 'Already have an account?',
    msg: {
      email: "Please enter a valid email address",
      mobile: "Please enter your mobile number",
      id: "Please enter a Device ID",
      minimum: "Passwords must be a minimum of 8 characters",
      match: "Password do not match",
      use: "Please agree to use the User Agreement and Privacy Agreement",
      characters: "Set a password of no less than 8 characters",
      register: "Register successfully",
    },
  },
  personalInformation: {
    msg: {
      company: 'Enter your company name cannot be empty',
      industry: 'Choose your industry cannot be empty',
      firstName: 'Enter your first name cannot be empty',
      lastName: 'Enter your last name cannot be empty',
    }
  },
  home: {
    pressure: 'Pressure',
    temperature: 'Temperature',
    humidity: 'Humidity',
    flow: 'Flow',
    frequency: 'Frequency',
    noise: 'Noise',
    torque: 'Torque',
    displacement: 'Displacement',
    speed: 'Speed',
    vibration: 'Vibration',
    illuminance: 'Illuminance',
    acceleration: 'Acceleration',
    gasConcentration: 'Gas Concentration',
    DI: 'DI',
    DO: 'DO',
    vibtate: 'VIBTATE',
    other: 'other',
    na: 'n/a',
    OFF: 'OFF',
    ON: 'ON',
    mg: 'mg',
  },
  confirmButtonText: 'confirm',
  cancelButtonText: 'cancel',
  router: {
    login: 'RIIAlert LOGIN',
  },
  configureDashboard: {
    deleteDashboard: 'Are you sure you want to delete this dashboard?',
    msg: {
      DashboardName: 'Dashboard name cannot be blank',
    }
  },
  configureAlarm: {
    confirms: {
      reboot: 'Are you sure you want to restart the device?',
    }
  },
  configureUsers: {
    confirms: {
      text: 'Are you sure you want to delete this User?',
    },
  },
  industry: {
    selectIndustry: 'Please select your industry',
    industries: [
      "Agriculture",
      "Apparel",
      "Automotive",
      "Banking",
      "Biotechnology",
      "Chemicals",
      "Communications",
      "Construction",
      "Consulting",
      "Education",
      "Electronics",
      "Energy",
      "Engineering",
      "Entertainment",
      "Environmental",
      "Finance",
      "Food&Beverage",
      "Government",
      "Healthcare",
      "Hospitality",
      "Insurance",
      "Machinery",
      "Manufacturing",
      "Media",
      "Not for Profit",
      "Oil&Gas",
      "Recreation",
      "Retail",
      "Service",
      "Shipping",
      "Technology",
      "Telecommunications",
      "Transportation",
      "Utilities",
      "Other",
    ],
    msg: {
      industryCannot: 'Enter industry cannot be empty',
    }
  },
  name: {
    enterName: 'Please enter your name'
  },
  app: {
    Log_IN: 'Log IN',
    Inviter: 'Inviter',
    owner: 'owner',
    SignOut: 'Sign out',
    CreateLinkedAccount: 'Create linked account',
    CONFIGURATION: 'CONFIGURATION',
    CONTACTS: 'CONTACTS',
    LinkedAccounts: 'Linked Accounts',
    confirmText: 'Are you sure you want to sign out?'
  },
  ModifySuccess: 'Modify successful',
  Modify_success_slot: '{slot} modify successful',
  Modify_failed_slot: '{slot} modify failed',
  LoginFailed: 'Login failed, please login again',
  serverIsBusy: 'server is busy',
  networkError: 'network error',
  DateFormat:'yyyy-MM-dd',
  pAlarmName:'Enter alarm name',
  SetPassword:'Set your password',
  ConfirmPassword:'Confirm your password',
  cPassword:'Confirm password',
  m_differ:'Entered passwords differ',
  Set:'Set',
  Reset:'Reset',
  ChooseType:'Choose type',
  ChooseChannel:'Choose channel',
  EnterDashboardName:'Enter Dashboard Name',
  cThresholdType:'Choose threshold type',
  noPermission:'I currently do not have permission to use this function. Please contact Business to activate it!',
  pOverview:{
    FaultHandlingRate:'Fault handling rate',
    ProcessedAlarm:'Processed alarm',
    AlarmTotal:'Alarm to date',
    RunTimeRatio:'Run time ratio',
    RunningTimeDays: 'Running time (days)',
    TotalDurationDays:'Total duration (days)',
    MaintenanceFrequencyThisMonth:'Maintenance times this month',
    ComparedToLastMonth:'Compared to last month',
    NumberOfInspectionsThisMonth:'Inspection times of this month',
    DeviceHealthStatus:'Device health status',
    DeviceHealth:'Device health',
    DeviceHealthLevel:'Device health level',
    DeviceMaintenanceReminder:'Device maintenance reminder',
    RecentMaintenanceTime:'Recent maintenance time',
    RemainingTimeForNextMaintenance:'Remaining time for next maintenance',
    DeviceAlarmRanking:"Device alarm ranking",
    DeviceAlarmTOP:"Device alarm TOP",
    timeList: {
      1: 'This month',
      2: 'Last month',
      3: 'Annual',
    },
    DeviceDeactivationStatistics:'Device deactivation statistics',
    frequency:'frequency',
    PlanDowntime:'Plan downtime',
    UnplannedDowntime:'Unplanned downtime',
    RunningTimeHours:'Running time',
    DeactivationTimes:'Deactivation times',
    hours:'hours'
  },
  pHome:{
    DeviceMonitoring:'Device monitoring',
    DeviceTotalNumber:'Device total number',
    NormalOperation:'Normal operation',
    SensorMonitoring:"Sensor monitoring",
    SensorTotalNumber:"Sensor total number",
    NormalCollection:"Normal collection",
    PendingAlarmRecords:"Pending alarm records",
    PendingAlarm:"Pending alarm",
    Handling:"Handling",
    DeviceStatusStatistics:"Device status statistics",
    AlarmProcessingStatistics:'Alarm processing statistics',
    Device_type_statistics:'Device type statistics',
    AlarmStatistics:'Alarm statistics',
    Device_alarm_trend:'Device alarm trend',
    Device_time_utilization_rate:'Device time utilization rate',
    StartDate:'Start Date',
    EndDate:'End Date',
    to:'to',
    Vibration_monitoring_point:'Vibration monitoring point',
    expert_report:'expert report',
    Reports_total:'Reports total',
    report_update_time:'Latest report update time',
    offline_notice:'Offline notice',
    Vibration_speed_detection:'Vibration speed detection',
  },
  DigitalLargeScreen:'Digital board',
  health:'Health',
  Good:'Good',
  warn:'Warn',
  dangerous:'Dangerous',
  SubHealth:'Sub health',
  fault:'Fault',
  offline:'Offline',
  unknown:'Unknown',
  A_level:'A level (Health)',
  B_level:'B level (Good)',
  C_level:'C level (Warning)',
  D_level:'D level (Dangerous)',
  Unpaid:'Unpaid',
  Processed:'Processed',
  PendingProcessing:'Pending processing',
  Processing:'Processing',
  Total:'Total',
  HighAlarm:'High alarm',
  lowAlarm:'Low alarm',
  High_low_alarm:'High low alarm',
  ISO_alarm:'ISO alarm',
  ISO_C_LevelAlarm:'ISO C level alarm',
  ISO_D_LevelAlarm:'ISO D level alarm',
  C_LevelAlarm:'C level alarm',
  D_LevelAlarm:'D level alarm',
  TemperatureAlarm:'Temperature_alarm',
  Alarm_time:'Alarm time',
  Operation:'Operation',
  Confirm_success:'Confirm successful',
  Please_select_data:'Please select data first',
  Search:'Search',
  pAlarm:{
    Time:'Time',
    Alarm_name:'Alarm name',
    Device_name:'Device name',
    Type:'Type',
    Status:'Status',
    Batch_confirm:'Batch confirm',
    Current_value:'Current value',
    Threshold:'Threshold',
    Update_time:'Update time',
    Creation_time:'Creation time',
    Processed_by:'Processed by',
    Confirmed:'Confirmed',
    Confirm:'Confirm',
    DI_alarm:'DI alarm',
    DO_alarm:'DO alarm',
    Alarming:'Alarming',
    Alarm_confirmed:'Alarm confirmed',
    Restored_normal_unconfirmed:'Restored normal unconfirmed',
    Restored_normal_confirmed:'Restored normal confirmed'
  },
  cancel_k:'cancel',
  confirm_k:'confirm',
  alertConfirmDialog:{
    Confirmed_alarm:'Confirmed alarm',
    Alarm_cause_confirmed:'Alarm cause confirmed',
    Processing_note:'Processing note',
    On_site_images:'On site images',
    Upload_img3_tip:'Only three images can be uploaded, and the size of each image should not exceed 5MB',
    alarm_cause:'alarm cause',
  },
  Please_select_slot:'Please select {slot}',
  Upload_three_images:'Upload up to three images',
  alertDetails:{
    Sensor_name:'Sensor name',
    value:' value',
    Alarm:'Alarm',
    Recovery:'Recovery',
    time:' time',
    Alarm_threshold:'Alarm threshold',
    Alarm_type:'Alarm type',
    Collection_time:'Collection time',
    Fault_cause_recommendation:'Fault cause recommendation',
    Fault_cause_confirmation:'Fault cause confirmation',
    view:'view',
    reason:'reason',
    role_alarm:'role alarm',
    system_alarm:'system alarm',
    notice:'notice',
  },
  count:'count',
  normal:'Normal',
  Operation_success:'Operation successful',
  start:'start',
  end:'end',
  No_Data:'No Data',
  historyData:{
    History_data:'History data',
    sensor:'sensor',
    Time_domain_data:'Time domain data',
    Download_data:'Download data',
    Refresh:'Refresh',
    Maximum_value:'Maximum value',
    Frequency_domain_data:'Frequency domain data',
    Click_device_model:'Click to optimize device model',
    Important_note:'Important note',
    model_confirm_tip:'This operation will optimize the device model with the selected data segment, please exercise caution!',
    Modeling_tip:'Modeling in progress, please wait...',
    hours_less_24_tip:'The interval cannot be less than 24 hours',
    Frequency_spectrum:'Frequency spectrum',
    Trend_waveform:'Trend waveform',
    Envelope_demodulation:'Envelope demodulation',
    Multiple_time_domain_waveform:'Multiple time domain waveform',
    Cepstrum_analysis:'Cepstrum analysis',
    Order_analysis:'Order analysis',
    Envelope_trend:'Envelope trend',
    Waterfall_plot:'Waterfall plot',
    Waveform_data:'Waveform data',
    Filter_data:'Filter data',
    Waveform_filter_data:'Waveform filter data',
  },
  switchTimeList:{
    3: 'Last 1 day',
    5: 'Last 3 day',
    6: 'Last 7 day',
    7: 'Custom time',
  },
  device: {
    DeviceName: 'Device name',
    DeviceType: 'Device type',
    DeviceArea: 'Device area',
    DeviceLevel: 'Device level',
    ISO_vibration_standard: 'ISO vibration standard',
    ISO_vibration_type: 'ISO vibration type',
    reboot: 'Are you sure you want to restart the device?',
    Health_status:'Health status',
    Running_status:'Running status',
    Number_of_sensors:'Number of sensors',
    Operations:'Operations',
    Running:'Running',
    Shutdown:'Shutdown',
    IOT_abnormality:'IOT abnormality',
    Device_working_status:'Device working status',
    Total_device_alarm_number:'Total device alarm number',
    pieces:' pieces',
    device_working_time:'Device working time',
    device_down_time:'Device down time',
    Device_info:'Device info',
    sensor_info:'Sensor info',
    details:'Details',
    Please_select_time:'Please select a time first',
    Sensor:'Sensor',
    click_view_data:'Click to view the spectrum at that time point',
    Device_fault_recommendation:'Device fault recommendation',
    Device_fault_recommendation_tip:'Recommended reference for equipment faults. If the equipment status continues to show abnormalities, attention should be paid to the higher scores in the fault classification',
    Device_status_monitoring:'Device status monitoring',
    Device_status_monitoring_tip:'Device status reference curve. If the data continues to exceed the threshold, you need to pay attention to it',
    Update_threshold_tip:'Reminder: This threshold has been in use for over 30 days, please update it promptly',
    State_threshold:'State threshold',
    Modeling:'Modeling',
    Add_device:'Add device',
    Please_select_device:'Please select the device first',
    Modeling_tip:'The device modeling will update the device algorithm model. During the modeling period, please keep the device running normally and steadily (3 hours after startup). The modeling time lasts for 24 hours, and it is recommended to conduct modeling every 30 days',
    Batch_modeling:'Batch modeling',
    del_device_tip:'Before deleting the device, please unbind it from the IOT module!',
    Delete_device:'Delete device',
    device:'device',
    Name:'Name',
    City:'City',
    Detailed_address:'Detailed address',
    Vibration_monitoring_switch:'Vibration monitoring switch',
    Standard_example:'Example',
    Reference_documents:'Reference',
    Remark:'Remark',
    Brand:'Brand',
    Level:'Level',
    Device_modeling:'Device modeling',
    Important_reminder:'Important reminder',
    Start:'Start',
    Sensor_list:'Sensor list',
    Unbind:'Unbind',
    Estimated_time_hours:'Estimated time (hours)',
    Schedule:'Schedule',
    Recent_start_time:'Recent start time',
    Recent_end_time:'Recent end time',
    trainingStatusList:{
      1: 'Data collection in progress',
      2: 'Algorithm model initialization',
      3: 'Modeling successful',
      4: 'Modeling failed',
      5: 'Invalid',
    },
    Start_modeling:'Start modeling',
    Continue_modeling:'Do you want to continue modeling',
    Please_select_sensor:'Please select the sensor to unbind first',
    Unbind_sensor_tip:'After Unbind the IOT configuration, the IOT data will become invalid and cleared. Please operate with caution!',
    Unbind_successful:'Unbind successful',
    Real_time_data_display:'Real time data display',
    Latest_update_time:'Latest update time',
  },
  operations:{
    Operations_info:'Operations info',
    Inspection_records:'Inspection records',
    Detection_records:'Detection records',
    Maintenance_records:'Maintenance records',
    Repair_records:'Repair records',
    Start_stop_records:'Start stop records',
    operationStatus:{
      0: 'abnormal',
      1: 'normal'
    },
    Import_failed:'Import failed',
    Import_successful:'Import successful',
    Inspection_content:'Inspection content',
    Select_date:'Select date',
    Inspection_status:'Inspection status',
    Inspection_date:'Inspection date',
    Inspector:'Inspector',
    Inspection_indicators:'Inspection indicators',
    Inspection_results:'Inspection results',
    Maintenance_status:'Status',
    Maintenance_manager:'Responsible person',
    Maintenance_amount:'Amount',
    Maintenance_responsible_unit:'Responsible unit',
    Maintenance_start_date:'Start date',
    Maintenance_end_date:'End date',
    Maintenance_date:'Maintenance date',
    Next_maintenance_date:'Next maintenance date',
    Registrant:'Registrant',
    Attachments:'Attachments',
    Repair_status:'Repair_status',
    Repair_responsible_person:'Responsible person',
    Repair_responsible_unit:'Responsible unit',
    Repair_start_date:'Start date',
    Repair_end_date:'End date',
    Repair_amount:'Amount',
    Repair_date:'Repair date',
    Detection_content:'Detection content',
    Detection_status:'Detection status',
    Detection_date:'Detection date',
    Internal_inspector:'Internal inspector',
    External_inspector:'External inspector',
    External_unit:'External unit',
    Detection_result:'Detection result',
    Start_stop_status:'Start stop status',
    Switch:'Switch',
    Reason_for_restoration:'Reason for restoration',
    Reason_for_discontinuation:'Reason for discontinuation',
    Suspension_measures:'Suspension measures',
    Boot:'Boot',
    startStopTypeSelected:{
      1: 'Scheduled shutdown',
      2: 'Unplanned shutdown',
      3: 'Scheduled boot',
      4: 'Unscheduled boot'
    }
  },
  upload_5Mb_tip:'Upload files not exceeding 5MB',
  el_upload_text1:'Drag the file here, or ',
  el_upload_text2:'click upload',
  Add_k:'Add ',
  Edit_k:'Edit ',
  Add:'Add',
  Edit:'Edit',
  Delete:'Delete',
  Template_download:'Template download',
  Import:'Import',
  Import_successful:'Import successful',
  template_slot:'{slot} template',
  del_record_tip:"Are you sure to delete this record?",
  Delete_successful:'Delete successful',
  Save:'Save',
  Add_successfully:'Add successfully',
  statisticalReport:{
    SN:'SN',
    Download_report:'Download',
    del_report_tip:'Are you sure to delete the report?'
  },
  area:{
    Area_name:'Area name',
    Add_area:'Add area',
    Delete_area:'Delete area',
    Delete_area_tip:'Handle with caution! Please ensure that there are no devices in the area!',
    Number_of_devices:'Number of devices',
  },
  IOT:{
    Inactive:'Inactive',
    IOT_name:'IOT name',
    IOT_SN:'IOT sn',
    IOT_model:'IOT model',
    IOT_type:'IOT type',
    Location:'Location',
    Communication_validity_period:'Validity period',
    Indefinite:'Indefinite',
    renew:'renew',
    Activate_network_communication:"Activate network communication",
    Install_date:"Install date",
    Disabled:'Disabled',
    Enable:'Enable',
    Alarm_config:'Alarm config',
    IOT_disabled_tip:'The IOT has been disabled',
    IOT_enabled_tip:'The IOT has been enabled',
    Channel_name:'Channel name',
    Unit:'Unit',
    High_alarm_deviation:'High alarm deviation',
    Low_alarm_deviation:'Low alarm deviation',
    Alarm_trigger_condition:'Alarm trigger condition',
    alarm_config_tip:'How many consecutive values exceed the alarm threshold, triggering an alarm',
    Close:'Close',
    Open:'Open',
    Basic_info:'Basic info',
    Add_sensor:'Add sensor',
    Activation_date:'Activation date',
    IOT_operation:'IOT operation',
    Restart_IOT:'Restart IOT',
    general_configuration:' general config',
    Basic_config:'Basic config',
    Sensor_config:'Sensor config',
    Sensor_type:'Sensor type',
    Model:'Model',
    Install_device:'Install device',
    upload_img_5mb_tip:'Please upload an image format file with a size of less than 5MB',
    enter_value_min_tip:'Please enter a value of no less than ',
    del_channel_tip:'Are you sure you want to delete this channel?',
    Channel_type:'Channel type',
    Source_select_channel:'Source (please select channel)',
    Restarting:'Restarting',
    Channel_modify_success:'Channel modify successful',
    Basic_info_modify_success:'Basic info modify success',
    complete_configuration_items:'Please complete the configuration items',
    config:' config',
    host:' host',
    slave:' slave',
    Baud_rate:'Baud rate',
    Address:'Address',
    server:' server',
    Port:'Port',
    client:' client',
    Remote_device_IP:'Remote device IP',
    Sensor_model:'Sensor model',
    Sensor_address:'Sensor address',
    enter_sensor_address:'Please enter sensor address ',
    enter_sensor_address_slot:'The sensor address must be between {slot}',
    Sensor_position:'Sensor position',
    rotary_speed:'Rotary speed',
    Nameplate:'Nameplate',
    image:'Image',
    Expand_information:'Expand information',
  },
  Save_successful:'Save successful',
  cannot_be_empty_slot:'{slot} cannot_be_empty_slot',
  user:{
    Add_role_user:'Add role user',
    Account:'Account',
    Role:'Role',
    Name:'Name',
    Device_group:'Device group',
    Pending_confirmation:'Pending confirmation',
    Edit_user:'Edit user',
    Add_user:'Add user',
    Invitation_method:'Invitation method',
    Role_type:'Role type',
    Assign_alarms:'Assign alarms',
    Assign_dashboard:'Assign dashboard',
    Mobile:'Mobile',
    Email:'Email',
    typeObj:{
      Mobile: 1,
      Email: 2,
      WeChat: 3,
    }
  },
  role:{
    Add_role:'Add role',
    Edit_role:'Edit role',
    Role_name:'Role name',
    Role_type:'Role type',
    built_in:'Built in',
    custom:'Custom',
    Menu_permissions:'Menu perms',
    Edit_menu_permissions:'Edit menu perms',
    del_role_tip:'Are you sure you want to delete this role?',
    Role_identifier:'Role identifier',
    Display_order:'Display order',
    Expand_Fold:'Expand/Fold',
    Select_All_Select_None:'Select all/Select none',
  },
  sms:{
    SMS_balance:'SMS balance',
    Recharge:'Recharge',
    Select_year:'Select year',
    Month:'Month',
    Consumption_quantity:'Consumption quantity',
    Recharge_record:'Recharge record',
    Monthly_billing:'Monthly billing',
    Recharge_account:'Recharge account',
    Recharge_SMS_volume:'Recharge SMS volume',
    Recharge_amount:'Recharge amount',
    Scan_pay:'Scan pay',
    Pay:'Pay',
    Select_package:'Select package',
    Payment_method:'Payment method',
    WeChat:'WeChat',
    Cost:'Cost',
    Go_pay:'Go pay',
    Select_package_tip:'Please select the package first'
  },
  alarmPush:{
    Alarm_push_config:'Alarm push config',
    push_tip:'Push interval must not be less than 10 minutes',
    Email_push_interval:'Email push interval (min)',
    SMS_push_interval:'SMS push interval (min)',
    Continuous_push_switch:'Continuous push switch',
    Save_k:'Save',
    Set_successfully:'Set successfully',
  },
  personal:{
    Personal_center:'Personal center',
    Role_identity:'Role identity',
    Role_nickname:'Role nickname',
    Edit_role_nickname:'Edit role nickname',
    Company_name:'Company name',
    Industry:'Industry',
    p_name:'Please fill in your name',
    p_company_name:'Please fill in your company name',
    p_industry:'Please select your industry',
    Account_manage:'Account manage',
    Change_password:'Change password',
    Device_group_settings:'Device group settings',
    Modify:'Modify',
    Binding:'Bind',
    Retrieve_again:'Retrieve again',
    Retrieve_again_slot:'Retry in {slot}s',
    p_modify_code:'Please enter the mobile verification code',
    p_email_code:'Please enter the email verification code',
    modify_code:'Mobile verification code',
    email_code:'Email verification code',
    p_new_mobile:'Please enter a new mobile number',
    p_new_email:'Please enter a new email address',
    get_code_tip:'Please click on bind or modify first to obtain the verification code',
    Binding_successful:'Binding successful',
    Old_password:'Old password',
    New_password:'New password',
    New_password_confirm:'New password confirm',
    Old_password_tip:'Please enter the old password',
    New_password_tip:'Please enter the new password',
    New_password_confirm_tip:'Please enter the confirmation password',
    password_tip:'Two passwords do not match',
    Device_group_alias:'Device group alias',
    Device_group_name:'Device group name',
    Device_group_sn:'Device group sn',
    Inviter:'Inviter',
    Creator:'Creator',
    Account_role:'Account role',
    Select_device:'Select device',
    Please_enter_device_name:'Please enter device name',
    Optional_device:'Optional device',
    Current_device:'Current device',
    Please_select_device:'Please select a device',
    del_device_group:'Are you sure you want to delete this device group?',

  },
  dashboard:{
    Show_all_channels:'Show all channels',
    Display_only_alarm_channels:'Display only alarm channels',
    Channel_configuration_parameters:'Channel params config',
    Channel_data:'Channel data',
    All:'All',
    confirmed:' confirmed',
    Dashboard_name:'Dashboard name',
    Add_dashboard:'Add dashboard',
    Creator_name:'Creator name',
    Modification_time:'Modification time',
    Default:'Default',
    default_dashboard_tip:'The default dashboard has been set',
    Edit_dashboard:'Edit dashboard',
    Selected_channel:'Selected channel',
    remove_all :'Remove all',
    p_dashboard_name:'Please enter the dashboard name',
  },
  alarm:{
    Alarm_description:'Alarm description',
    Alarm_value:'Alarm value',
    Please_remarks:'Please fill in the remarks',
    Please_pictures:'Please upload on-site pictures',
    device_code:'device code',
    Alarm_frequency:'Alarm frequency',
    Alarm_trend:'Alarm trend',
  },
  Download_history:'Download history',
  Device_model:'Device model',
  Download_mode:'Download mode',
  Date:'Date',
  Select_data:'Select data',
  Data_packet:'Data packet',
  p_download_mode:'Please select download mode',
  p_select_date:'Please select a date',
  Download_time:'Download time',
  Device_SN:'Device SN',
  Download_again:'Download again',
  Downloading_please_wait:'Downloading, please wait...',
  Add_Modbus:'Add Modbus',
  Alarm_configuration:' alarm configuration',
  high_and_low_err:'Error in high and low security data',
  p_time:'Please select a time',
  device_disabled:'The device has been disabled',
  device_enabled:'The device is enabled',
  Add_Modbus_virtual_device:'Add Modbus virtual device',
  Parent_device:'Parent device',
  Continuous_address_read_multiple:'Continuous address read multiple',
  p_name_10:'Please enter a name within 10 digits',
  Device_address:'Device address',
  p_0_255:'Please enter addresses from 0 to 255',
  Yes:'Yes',
  No:'No',
  Universal_Modbus:'Universal Modbus',
  p_parent_device:'Please select the parent device',
  p_select_model:'Please select the model',
  p_device_address:'Please enter the device address',
  Adding_please_wait:'Adding,please wait...',
  Add_Modbus_channel:'Add Modbus channel',
  p_channel_name:'Please enter the channel name',
  Register_address:'Register_address',
  channel:' channel',
  Add_alarm_rules:'Add alarm rules',
  Creation_date:'Creation date',
  Creator:'Creator',
  Tip:'Tip',
  Role_alarm_name:'Role alarm name',
  P_role_alarm_name:'Please enter the role alarm name',
  p_select_channel:'Please select a channel',
  p_select_type:'Please select the type',
  p_alarm_value:'Please enter a high alarm value',
  p_floating_value:'Please enter the high alert floating value',
  p_low_value:'Please enter a low alarm value',
  p_float_low_value:'Please enter the float value for low reporting',
  low_high_tip:'The low reported value cannot be greater than the high reported value',
  chartDateRangeList : [
    {label: 'Custom', value: 0},
    {label: '1 hour', value: 1},
    {label: '6 hour', value: 2},
    {label: '1 day', value: 3},
    {label: '7 day', value: 4},
  ],
  Abnormal_device:'Abnormal device list',
  Abnormal_sensor:'Offline sensor list',
  Maintenance_content:'Maintenance content',
  Report_Name:'Report name',
  logo_phm_title:'PHM',
  collector:{
    add:'Add collector',
    edit:'Edit collector',
    name:'Collector name',
    sn:'Collector sn',
    type:'Collector type',
    model:'Collector model',
    Alarm_config:'Alarm config',
    customize:'Customize',
    ISO_Standard:'ISO standard',
    Alarm_level:'Alarm level',
    X_intensity:'X-axis intensity ',
    Y_intensity:'Y-axis intensity ',
    Z_intensity:'Z-axis intensity ',
  }
}


