import Vue from 'vue'
import Vuex from 'vuex'
import storage from "../fetch/storage";
import i18n from "@/i18n"
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        themeName: "custom",
        themeStyle: {
            "custom": ``,
            "default": `
			--nav-bg:#f79230;
			--nav-color:#ffffff;
		`,
        },
        activeMenu: storage.get('activeMenu'),
        initRegisteredLogin: false,
        menus: [],
        permissions: [],
        tenantId: '',
        tenantIdBt:process.env.VUE_APP_TENANT_BT_ID,
        redirectPath:'',
        isBt:false,
        isLt:false,
        isXbk:false,
        isDt:false,
        tenantKey:''
    },
    getters: {
        theme(state) {
            const storageColor = storage.get('bg')
            let color = '#4D648D'
            if (storageColor) {
                color = storageColor
            } else {
                storage.set('bg', color)
            }
            state.themeStyle[state.themeName] = `
			--nav-bg:${color};
			--nav-color:#ffffff;
			--layout-bg:#00713A;
			--layout-header:#007E3A;
			--layout-color:#FFFFFF;
			--layout-menu-hover:#35a568;
		`
            return state.themeStyle[state.themeName]
        }
    },
    mutations: {
        updateActiveMenu(state, activeMenu) {
            state.activeMenu = activeMenu
        },
        setTheme(state, themeName) {
            state.themeName = themeName
        },
        setColor(state, color) {
            state.themeStyle.custom = `--nav-bg:${color};`
        },
        setInitRegisteredLogin(state, initRegisteredLogin) {
            state.initRegisteredLogin = initRegisteredLogin
        },
        setTenantKey(state,tenantKey){
            state.tenantKey = tenantKey
        },
        setTenantId(state, tenantId) {
            state.tenantId = tenantId
            if(tenantId == process.env.VUE_APP_TENANT_BT_ID){
                state.isBt = true
            }else if(tenantId == process.env.VUE_APP_TENANT_LT_ID){
                state.isLt = true
                localStorage.setItem('language', 'zh_CN')
                i18n.locale = 'zh_CN'
            }else if(tenantId == process.env.VUE_APP_TENANT_XBK_ID){
                state.isXbk = true
            }else if(JSON.parse(process.env.VUE_APP_TENANT_DT_ID).includes(tenantId)){
                state.isDt = true
            }
            // state.isDt = true //临时
        },
        setStateValue(state, item) {
            state[item.key] = item.value
        }
    }
})

export default store
